.App {
  text-align: center;
}

.App-header {
  background-color: #7958a3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 1rem 2rem;
}

.header-links {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.header-links > * {
  color: #fff;
  text-decoration: none;
}
/* .header-links > *:hover {
  color: #ff7300;
} */
.link-selected {
  border-bottom: 1px solid;
  border-color: #fff;
}
/* .link-selected > *:hover {
  border-color: #ff7300;
} */

.calendar-cell {
  border: 1px solid transparent !important;
}
.calendar-badge-secondary {
  background: rgb(255, 115, 0, 0.5) !important;
  border-radius: 50%;
}

.calendar-badge-primary {
  background: rgb(121, 88, 163, 0.5) !important;
  background-color: #ff7300;
  fill: #ff7300;
  border-radius: 50%;
}

.react-calendar__tile--active {
  font-weight: bolder !important;
}

.react-calendar button {
  border-radius: 50% !important;
}

/* Meeting bubble next to calendar */

.bubble-side {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 6px #b2b2b2;
  display: inline-block;
  padding: 10px 18px;
  position: relative;
  vertical-align: top;
  /* float: left; */
  margin: 5px 45px 5px 20px;
}

.bubble-side::before {
  background-color: #ffffff;
  content: "\00a0";
  display: block;
  height: 16px;
  position: absolute;
  top: 11px;
  transform: rotate(29deg) skew(-35deg);
  -moz-transform: rotate(29deg) skew(-35deg);
  -ms-transform: rotate(29deg) skew(-35deg);
  -o-transform: rotate(29deg) skew(-35deg);
  -webkit-transform: rotate(29deg) skew(-35deg);
  width: 20px;
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  left: -9px;
}

.bubble-under {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 6px #b2b2b2;
  display: inline-block;
  padding: 10px 18px;
  position: relative;
  vertical-align: top;
  margin: 25px 0px 5px; /* Adjust top margin to create space for the triangle */
}

.bubble-under::before {
  background-color: #ffffff;
  content: "\00a0";
  display: block;
  height: 20px;
  position: absolute;
  top: -10px; /* Position it above the box */
  left: 20px; /* Center the triangle horizontally relative to the box */
  transform: rotate(45deg); /* Rotate to create the upward triangle effect */
  width: 20px;
  box-shadow: -2px -2px 2px 0 rgba(178, 178, 178, 0.4);
}
